import React from "react";
import img1 from "../assets/images/png/img01.png";
import img2 from "../assets/images/png/img02.png";

function Instructions() {
  return (
    <div id='page2' className='' style={{ display: "flex", opacity: 1 }}>
      <div style={{ position: "relative" }}>
        <div className='instructionWrapper'>
          <p>Take a high-quality, well-lit image of the!</p>
          <div className='instruction-div'>
            <img className='instruction-img' src={img1} />
            <img className='instruction-img' src={img2} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Instructions;
