import React, { useState } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

function Landing({ onSelectChange }) {
  const [selectedOption, setSelectedOption] = useState("");

  return (
    <div
      id='page1'
      className='main'
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 10,
      }}
    >
      {/* <h1>Christmas AI</h1> */}
      {/* <p className='subText'>
        This festive season, use this incredible AI-powered tool that transforms
        your space with stunnig Christmas decorations &amp; lighting!
      </p> */}
      <div className='spaceDropdownContainer'>
        <div className='dropDownContent abs'>
          <p className='spaceDDText'>
            Pick the space <br /> you want to customize
          </p>
          {/* <FontAwesomeIcon icon={["fab", "apple"]} /> */}
          <div className='select-wrapper'>
            <select
              id='standard-select'
              value={selectedOption}
              onChange={(event) => {
                onSelectChange(event.target.value);
                setSelectedOption(event.target.value);
              }}
            >
              <option className='default-select' value='' disabled hidden>
                Select an option...
              </option>

              <option value='Your office'>Your office</option>
              <option value='A street'>A street</option>
              <option value='Your living room'>Your living room</option>
              <option value='Your bedroom'>Your bedroom</option>
              <option value='Your dining room'>Your dining room</option>
              <option value='Your verandah/outdoor living space'>
                Your verandah/outdoor living space
              </option>
            </select>
            <FontAwesomeIcon className='icon-caret' icon={faCaretDown} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
