import axios from "axios";
import React, { useEffect, useState } from "react";
import AppModal from "../components/AppModal";
import ImageCropper from "../components/ImageCropper";
import Instructions from "../components/Instructions";
import Landing from "../components/Landing";
import Spinner from "../components/Spinner";
import Spinner2 from "../components/Spinner2";
import ModalImage from "react-modal-image";

function Home() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [isloading, setIsloading] = useState(false);

  const [landingVisible, setlandingVisible] = useState(true);
  const [instructionsVisible, setinstructionsVisible] = useState(false);
  const [uploadFieldVisible, setuploadFieldVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [initialImage, setinitialImage] = useState(null);

  const [showSpinner, setShowSpinner] = useState(true);

  const [blob, setBlob] = useState(null);
  const [inputImg, setInputImg] = useState("");
  const getBlob = (blob) => {
    // pass blob up from the ImageCropper component
    setBlob(blob);
  };

  useEffect(() => {
    // Simulate a delay of 3 seconds (adjust as needed)
    const delay = 2500;

    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  const showNextSlide = () => {
    if (landingVisible) {
      setlandingVisible(false);
      setinstructionsVisible(true);
    } else if (instructionsVisible) {
      setinstructionsVisible(false);
      setuploadFieldVisible(true);
    }
  };

  const handleRefresh = () => {
    setlandingVisible(true);
    setinstructionsVisible(false);
    setuploadFieldVisible(false);
    setGeneratedImage(null);
    setinitialImage(null);
    setSelectedOption(null);

    // localStorage.clear();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const onInputChange = (e) => {
    // convert image file to base64 string
    setModalOpen(true);
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setInputImg(reader.result);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmitImage = async (e) => {
    const imageFile = new File([blob], "image.png", { type: "image/png" });
    const beforeImage = await toBase64(imageFile);
    if (beforeImage) {
      setinitialImage(beforeImage);
    }
    generateImage(imageFile);
    handleCloseModal();
  };

  const generateImage = async (imageSource) => {
    const engineId = "stable-diffusion-xl-1024-v1-0";
    const apiHost = process.env.API_HOST ?? "https://api.stability.ai";
    const apiKey =
      process.env.STABILITY_API_KEY ??
      "sk-1JHlX8BSzBWNv7Mp8bj2isnGiXBkoaN4Z41m5rqJpD5hzAZ5";

    if (!apiKey) throw new Error("Missing Stability API key.");

    setIsloading(true);

    const formData = new FormData();
    formData.append("init_image", imageSource);
    formData.append("init_image_mode", "IMAGE_STRENGTH");
    formData.append("image_strength", 0.35);
    //
    formData.append(
      "text_prompts[0][text]",
      `${selectedOption}, Create a warm and vibrant Christmas setting with twinkling lights, beautifully decorated Christmas tree, candles, snowy accents, festive ornaments, and wreath with holiday greenery to make a vibrant, extravagant Christmas style of decoration.`
    );
    formData.append("text_prompts[0][weight]", 1);
    formData.append(
      "text_prompts[1][text]",
      "longbody, lowres, bad anatomy, bad hands, missing fingers, extra digit, fewer digits, cropped, worst quality, low quality, green, hot pink"
    );
    formData.append("text_prompts[1][weight]", -1);

    formData.append("cfg_scale", 7);
    formData.append("samples", 1);
    formData.append("steps", 30);

    axios
      .post(`${apiHost}/v1/generation/${engineId}/image-to-image`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${apiKey}`,
        },
      })
      .then((response) => {
        console.log("Response:", response.data);
        const { artifacts } = response.data;
        setGeneratedImage(artifacts[0].base64);
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const handleSelectChange = (option) => {
    setSelectedOption(option);
    localStorage.setItem("landingVisible", "false");
  };

  return (
    <div className='home-back'>
      {showSpinner ? (
        <div className='spinner-container'>
          <Spinner />
        </div>
      ) : null}

      <AppModal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div
          style={{
            maxWidth: "460px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className='react-crop-div'>
            {inputImg && (
              <div>
                <ImageCropper getBlob={getBlob} inputImg={inputImg} />
                <button
                  className='btn-before modal-close-button'
                  style={{ zIndex: 100000 }}
                  onClick={handleSubmitImage}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      </AppModal>
      <>
        {landingVisible && (
          <div className='hero-text-wrapper'>
            <h2 className='hero-text'>
              Transform your space into a Christmas Wonderland!
            </h2>
            <div className='wrapper-window'>
              <div className='red'></div>
              <div className='rounded-window'>
                <div className='round-in'>
                  <Landing onSelectChange={handleSelectChange} />
                </div>
              </div>
            </div>

            <button
              className='next-button'
              onClick={showNextSlide}
              disabled={!selectedOption}
            >
              Start
            </button>
          </div>
        )}

        {instructionsVisible && (
          <div className='hero-text-wrapper'>
            <h2 className='hero-text'>
              Transform your space into a Christmas Wonderland
            </h2>
            <div className='wrapper-window'>
              <div className='red'></div>
              <div className='rectangle-window'>
                <div className='rect-in'>
                  <Instructions />
                </div>
              </div>
            </div>

            <button className='next-button' onClick={showNextSlide}>
              Next
            </button>
          </div>
        )}

        {uploadFieldVisible && (
          <>
            {isloading ? (
              <div className='image-upload-spinner-container'>
                <Spinner2 />
              </div>
            ) : (
              <div className='hero-text-wrapper'>
                <h2 className='hero-text'>
                  Transform your space into a Christmas Wonderland
                </h2>
                {generatedImage ? (
                  <div className='last-wrapper' style={{ maxWidth: "860px" }}>
                    <div
                      className='generate-wrapper'
                      style={{ display: "flex", gap: "10px" }}
                    >
                      <div>
                        {!!initialImage && (
                          <ModalImage
                            small={initialImage}
                            large={initialImage}
                            alt='Uploaded Image'
                            style={{
                              maxWidth: "380px",
                              height: "auto",
                              borderRadius: "15px",
                            }}
                            className='img-before'
                          />
                        )}
                        <p className='text-before'>Before</p>
                      </div>
                      <div>
                        <ModalImage
                          small={`data:image/png;base64,${generatedImage}`}
                          large={`data:image/png;base64,${generatedImage}`}
                          alt='Result Image'
                          style={{
                            maxWidth: "380px",
                            height: "auto",
                            borderRadius: "15px",
                          }}
                          className='img-before'
                        />
                        <p className='text-before'>After</p>
                      </div>
                    </div>
                    <div
                      className='btn-wrapper-download'
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginTop: "20px",
                        flexDirection: "column",
                      }}
                    >
                      {/* <a
                        download='generated_image.png'
                        href={`data:image/png;base64,${generatedImage}`}
                        className='btn-before'
                      >
                        Download
                      </a> */}
                      <p className='text-view'>
                        Tap to view the image. Long press to save.
                      </p>
                      <a onClick={() => handleRefresh()} className='btn-before'>
                        Refresh
                      </a>
                    </div>
                  </div>
                ) : (
                  <>
                    <div>
                      <div className='wrapper-window'>
                        <div className='red'></div>
                        <div className='rounded-window'>
                          <div className='round-in upload-div'>
                            <input
                              className='custom-file-input'
                              type='file'
                              accept='image/*'
                              style={{ display: "none" }}
                              id='customFileInput'
                              onChange={onInputChange}
                            />
                            <i
                              className='fa-solid fa-image'
                              style={{ color: "white", fontSize: "3rem" }}
                            ></i>
                            <div className='upload-image'>Upload Image</div>
                          </div>
                        </div>
                      </div>

                      <label
                        htmlFor='customFileInput'
                        style={{
                          padding: "8px 40px",
                          borderRadius: "50px",
                          cursor: "pointer",
                          fontSize: "22px",
                          zIndex: 1,
                        }}
                        className='next-button width-btn'
                      >
                        Choose Image
                      </label>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
}

export default Home;
