import React from "react";

function Spinner() {
  return (
    <div className='spinner-container'>
      <div>Unwrapping Joy ...</div>
    </div>
  );
}

export default Spinner;
